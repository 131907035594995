var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accept-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "退货验收",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "退货查找"
    },
    on: {
      "click-right": _vm.toAfterSearchView
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, _vm._l(_vm.lines, function (line, index) {
    return _c('van-cell', {
      key: index,
      staticStyle: {
        "height": "50px"
      },
      attrs: {
        "center": "",
        "title": line.name,
        "is-link": "",
        "value": line.after_orders_count ? line.after_orders_count + '张退货单待验收' : ''
      },
      on: {
        "click": function click($event) {
          return _vm.toListView(line);
        }
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }