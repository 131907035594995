<template>
  <div class="accept-container">
    <my-nav-bar
      title="退货验收"
      left-text="返回"
      left-arrow
      right-text="退货查找"
      @click-right="toAfterSearchView"
    />
    <div class="app-main-container">
      <van-cell
        v-for="(line, index) in lines"
        :key="index"
        style="height: 50px"
        center
        :title="line.name"
        is-link
        :value="line.after_orders_count ? line.after_orders_count + '张退货单待验收' : ''"
        @click="toListView(line)"
      />
    </div>
  </div>
</template>

<script>
import lineApi from '@/api/line'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'AcceptIndex',
  components: { myNavBar },
  data() {
    return {
      lines: null
    }
  },
  created() {
    this.getLines()
  },
  methods: {
    getLines() {
      this.beginLoad()
      lineApi.accept().then(res => {
        this.lines = res.data
        this.endLoad()
      })
    },
    toListView(line) {
      this.$router.push({ path: '/after-accept-list', query: { line_name: line.name, line_id: line.id, type: line.type }})
    },
    toAfterSearchView() {
      this.$router.push({
        path: '/after-search'
      })
    }
  }
}
</script>

<style scoped>

</style>
