import request from '@/utils/request'

export default {

  accept() {
    return request({
      method: 'get',
      url: 'line/accept'
    })
  },
  review() {
    return request({
      method: 'get',
      url: 'line/review'
    })
  },
  list() {
    return request({
      method: 'get',
      url: 'line'
    })
  },
  deliverySort() {
    return request({
      method: 'get',
      url: 'line/delivery/sort'
    })
  },
  loadPick() {
    return request({
      method: 'get',
      url: 'line/load/pick'
    })
  },
  loadReview() {
    return request({
      method: 'get',
      url: 'line/load/review'
    })
  }

}

export function getLines() {
  return request({
    method: 'get',
    url: 'line'
  })
}
